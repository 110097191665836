// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-templates-news-tsx": () => import("./../../../src/components/templates/news.tsx" /* webpackChunkName: "component---src-components-templates-news-tsx" */),
  "component---src-components-templates-page-tsx": () => import("./../../../src/components/templates/page.tsx" /* webpackChunkName: "component---src-components-templates-page-tsx" */),
  "component---src-pages-app-tsx": () => import("./../../../src/pages/app.tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-pages-experiment-tsx": () => import("./../../../src/pages/experiment.tsx" /* webpackChunkName: "component---src-pages-experiment-tsx" */)
}

