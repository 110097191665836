import "toastr/toastr.scss";

export const onInitialClientRender = () => {
  // $(document).ready(function () {
  //   $('body').css({'opacity': 0});
  //
  //   setTimeout(function() {
  //     $( "body" ).animate({
  //       opacity: 1,
  //     }, 300, function() {
  //       // Animation complete.
  //     });
  //   }, 300)
  // });
};
